.header-user-menu {
  margin: 12px @spacing-16;
  .menu-icon {
    display: block;
    cursor: pointer;
  }
}

.header-user-dropdown {
  .userItem {
    padding-top: @spacing-16 !important;
    padding-bottom: @spacing-16 !important;
    cursor: default;
    .name {
      max-width: 100%;
      color: @text-senior-color;

      .font-16_h20_B();
      .text-overflow-hidden();
    }
    .email {
      max-width: 100%;
      color: @text-base-color;
      font-size: 12px;
      line-height: 16px;

      .font-14_h20_Re();
      .text-overflow-hidden();
    }
  }
}

@hack: true; @import (reference) "~@zenlayer/zen-design/esm/style/index.less";