@sidebar-item-padding-left: @spacing-16;
.siderbar-team-wrap {
  position: relative;
  padding: 0 @sidebar-item-padding-left;
  height: 100%;
  display: flex;
  align-items: center;
  // &:hover {
  //   background: @dropdown-item-hover-bg;
  // }
  border-radius: 0 0 @sidebar-item-padding-left @sidebar-item-padding-left;
}

.team {
  width: 100%;
  display: flex;
  align-items: center;
  color: @text-base-color;
  .rocket {
    transition: width 0.3s, height 0.3s;
    width: 24px;
    height: 24px;
    margin-right: @spacing-8;
  }
  .select {
    .font-14_h16_B();
    width: 168px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .name {
      flex: 1;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: @spacing-8;
    }
  }
  &&-collapsed {
    .select {
      display: none;
    }
    .rocket {
      width: 32px;
      height: 32px;
    }
  }
}

.dropdown {
  background-color: @elevation-mix-4;
  border-radius: @dropdown-border-radius;
  box-shadow: @box-shadow-4;
  padding: @spacing-16 0;
  position: absolute;
  top: 64px;
  width: 320px;
  left: 0;
  z-index: @dropdown-zIndex;

  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
  &.visible {
    pointer-events: auto;
    opacity: 1;
  }

  .item:not(.divider):not(.groupTitle) {
    padding: @dropdown-item-padding;

    height: @dropdown-item-minHeight;
    color: @dropdown-base-color;
    .font-14_h20_Re();
    display: flex;
    align-items: center;
    &:hover {
      background: @dropdown-item-hover-bg;
      cursor: pointer;
    }
    &.selected {
      background: @dropdown-item-selected-bg;
      color: @primary-highTop-color;
    }
  }
  .groupTitle {
    min-height: @dropdown-group-title-minHeight;
    color: @dropdown-group-title-color;
    .font-14_h18_B();
    display: flex;
    align-items: center;
    padding: @dropdown-item-padding;
  }
  .divider {
    border-bottom: 1px solid @dropdown-divider-color;
  }

  .scroll {
    max-height: calc(@dropdown-item-minHeight * 5);
    overflow-y: auto;
    .mixin-set-scrollbar();
  }

  .rocket {
    width: 24px;
    height: 24px;
  }
}

.vipTag {
  .font-12_h16_Re();
  background: @warning-secondary-color;
  padding: 0 @spacing-4;
  border-radius: @base-border-radius;
  color: @text-senior-color;
}
.partnerTag {
  .font-12_h16_Re();
  background: @success-secondary-color;
  padding: 0 @spacing-4;
  border-radius: @base-border-radius;
  color: @text-senior-color;
}

@hack: true; @import (reference) "~@zenlayer/zen-design/esm/style/index.less";